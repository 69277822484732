import {performInventoryFetch} from '../../common/fetch'
import * as treatmentOverviewSimulation from './treatmentOverviewSimulation';
import useSimulationData from "../../../helpers/simulationHelper";

/**
 * Method to perform the remote query for the treatment overview display data
 */
const fetchTreatmentOverviewDisplayData = (datavan) => {
  return performInventoryFetch({
      'query': 'query getLoadedJob($datavan:String){ getLoadedJob(datavan: $datavan) {name fleet pad customer jobPlanStartDate jobPlanEndDate status notes processType datavan wells { name apiNumber } sides { name displayName } }}',
      'variables': {
          'datavan': datavan
      }
  })
};

const fetchSaveUserDashboard = (input) => {
  if (useSimulationData('userDashboardSetting')) {
    return {
        'data': {
            'saveUserConfig': [{
                'id': 123,
                'category': 'dashboard',
                'name': 'treatmentOverview',
                'description': null,
                'value': JSON.stringify(input.definitions[0].value)
            }]
        }
    };
  }
  return performInventoryFetch(
    {
      'query': 'mutation saveUserConfig($input:UserConfigInput!) { saveUserConfig(input: $input) { id category name description value createdOn modifiedOn } }',
      'variables': {
        'input': input
      }
    });
};

const fetchTreatmentOverviewCards = () => {
    return treatmentOverviewSimulation.fetchTreatmentOverviewCards();
}

export {
  fetchTreatmentOverviewDisplayData,
  fetchSaveUserDashboard,
  fetchTreatmentOverviewCards
};
