import _ from "lodash";


const resolveSelectedContext = (contexts, contextId) => {

  let selectedContext = null;

  // Validate that we have a context with the requests id
  if (!_.isNil(contextId) && !_.isEmpty(contexts)) {
    let match = _.find(contexts, { id: contextId });
    selectedContext = _.isNil(match) ? null : match;
  }

  // If we did not resolve a selected context, but we do have contexts, set it to the first element.
  if (_.isNil(selectedContext) && !_.isEmpty(contexts)) {
    selectedContext = contexts[0];
  }

  return selectedContext;
};

const processJob = (job) => {

  let contexts = [];
  let id = 0; // This is the contextId

  if (!_.isNil(job) && !_.isEmpty(job.sides)) {
    contexts = _.orderBy(_.uniqBy(job.sides, 'name'), 'name').map(side => {
      return {
        id: id++,
        value: side.name,
        label: side.displayName,
      }
    });
  }

  return {
    contexts: contexts
  };
};

export {
  resolveSelectedContext,
  processJob
}