import React, { Component } from "react";
import _ from "lodash";
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import { appState as applicationState } from '../../../../state/app/appSelectors';
import MDTDataGrid from '../../../common/table/MDTDataGrid';
import * as userConfigActions from '../../../../state/app/actions/appUserConfigActions';
import { treatmentOverviewState } from "../../../../state/displays/treatmentOverview/treatmentOverviewSelectors";
import ComponentTypes from "../../../componentTypes";
import OWNERS from "../../../common/owners";
import { getCardCountInViewForCardType } from "../../../common/layout/layoutHelper";

import { Box } from '@mui/system';
import { Button, Stack, Typography } from "@mui/material";

import WatchLaterIcon from '@mui/icons-material/WatchLater';
import AirIcon from '@mui/icons-material/Air';
import VibrationIcon from '@mui/icons-material/Vibration';
import OutputIcon from '@mui/icons-material/Output';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import SensorsIcon from '@mui/icons-material/Sensors';
import BoltIcon from '@mui/icons-material/Bolt';
import HttpsIcon from '@mui/icons-material/Https';
import CategoryIcon from '@mui/icons-material/Category';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import TimelineIcon from '@mui/icons-material/Timeline';
import GridViewIcon from '@mui/icons-material/GridView';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import ListIcon from '@mui/icons-material/List';

const styles = {
    cardsList: {
        minHeight: 'calc(100vh - 180px)'
    },
    cardsContent: {
        display: 'flex',
        height: '100%'
    },
    dataGrid: {
        border: 'none',
        '& .MuiDataGrid-columnHeaders': {
            border: 'none'
        },
        '& .MuiTextField-root': {
            width: '100%'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus':
        {
            outline: 'none',
        },
        '& .MuiDataGrid-row.Mui-selected': {
            '&:hover': {
                backgroundColor: '#ce4300'
            },
            backgroundColor: 'grey.800'
        }
    },
    cardDescription: {
        wordWrap: 'break-word',
        overflow: 'hidden',
        whiteSpace: 'normal'
    },
    addButtonBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: '65px',
        width: '100%'
    },
    addButton: {
        margin: '10px'
    }
}

const unitUserChartLimit = 8;

class ConfigPanelTreatmentOverview extends Component {

    render() {
      // Limit the user to 8 (unitUserChartLimit) per view. Check the id of the selected view (if it's custom) or just use the selected view (system views do not have ids)
      const unitUserChartCount = getCardCountInViewForCardType(this.props.dashboards[ComponentTypes.TREATMENT_OVERVIEW]?.views, this.props.selectedView?.id || this.props.selectedView, ComponentTypes.UNIT_USER_CHART);
      // Updating the compare to >= instead of === since we now have users that have exceeded the limits as a result of DE23196
      const cannotAddMoreUnitUserChartCards = this.props.selectedCard?.type === ComponentTypes.UNIT_USER_CHART && unitUserChartCount >= unitUserChartLimit;

      return (
        <Stack spacing={1}>
            <Box sx={styles.cardsList}>
                <Box sx={styles.cardsContent} >
                    <MDTDataGrid
                        sx={styles.dataGrid}
                        autoHeight={false}
                        minHeight='40px'
                        getRowHeight={() => 'auto'}
                        // Used to track when a card has been selected
                        onSelectionModelChange={([id]) => {
                            if (_.isNil(id)) {
                                this.props.onSelectCard(null);
                            } else {
                                this.props.onSelectCard(this.props.cardsList.find(card => card.id === id));
                            }
                        }}
                        rowSelectionModel={this.props.selectedCard}
                        selectionModel={this.props.selectedCard ? [this.props.selectedCard.type] : []}
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnResize
                        disableMultipleSelection
                        columns={[
                            {
                                field: 'icon',
                                editable: false,
                                hideable: false,
                                pinnable: false,
                                width: 25,
                                minWidth: 0,
                                height: 25,
                                renderCell: (params) => {
                                  return (
                                    <div>
                                      {params.row.icon}
                                    </div>
                                    )
                                }
                            },
                            {
                                field: 'text',
                                editable: false,
                                hideable: false,
                                pinnable: false,
                                flex: 1,
                                renderCell: (params) => {
                                    return (
                                        <div style={{ margin: '10px', cursor:'default' }}>
                                            <Typography>{params.row.text.title}</Typography>
                                            <Typography sx={styles.cardDescription} fontSize={12}>{params.row.text.description}</Typography>
                                        </div>
                                    )
                                },
                                // Since search fields are nested, take text property and turn into a delimited string
                                // This lets us apply the quick filter against the data in here
                                valueGetter: (params) => {
                                    if (_.isNil(params.row)) return '';
                                    return params.row.text.title + '/' + params.row.text.description;
                                }
                            }]}
                        rows={this.props.cardsList}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                printOptions: {
                                    disableToolbarButton: true
                                },
                                csvOptions: { disableToolbarButton: true }
                            }
                        }}
                        stateDef={this.props.stateDef}
                        pagination={false}
                        getRowId={(row) => row.id}
                        hideFooter
                        headerHeight={0}
                    />
                </Box>
            </Box>
            <Box sx={{display: 'flex', flexFlow: 'column nowrap'}}>
              <Box sx={{display: 'flex', paddingRight: '10px', flexFlow: 'row-reverse', position: 'relative', top: '-20px'}} visibility={cannotAddMoreUnitUserChartCards ? 'visible' : 'hidden'} >
                <Typography variant="subtitle2">{'Maximum number ('}{unitUserChartLimit}{') of Unit User Charts reached.'}</Typography>
              </Box>      
              <Box sx={styles.addButtonBox}>
                <Button sx={styles.addButton} variant="contained" disabled={(_.isNil(this.props.selectedCard) || cannotAddMoreUnitUserChartCards)} onClick={() => { this.props.onAddCardToDashboard(this.props.selectedView, this.props.selectedCard); }}>
                    Add
                </Button>
              </Box>
            </Box>
        </Stack>
      )
    }
}

const stateDefinition = (props) => {
    return {
        stateDef: {
            key: _.isNil(props.stateKey) ? ComponentTypes.TREATMENT_OVERVIEW : props.stateKey,
            type: ComponentTypes.TREATMENT_OVERVIEW,
        }
    }
};

const getDisplay = (type, owner) => {
    switch (type) {
        case ComponentTypes.UNIT_CHART_OUTPUT_HISTORY:
            return (_.isNil(owner?.value) ? false : owner.value !== OWNERS.NEXTIER && owner.value !== OWNERS.MDT);
        case ComponentTypes.UNIT_CHART_VIBRATION_HISTORY:
            return (_.isNil(owner?.value) ? false : owner.value === OWNERS.NEXTIER || owner.value === OWNERS.MDT);
        default:
            return true
    }
}

const getIcon = (type) => {
    switch (type) {
        case ComponentTypes.UNIT_HOURS:
            return <WatchLaterIcon />;
        case ComponentTypes.UNIT_CHART_PRESSURE_HISTORY:
            return <AirIcon />;
        case ComponentTypes.UNIT_CHART_VIBRATION_HISTORY:
            return <VibrationIcon />;
        case ComponentTypes.UNIT_CHART_OUTPUT_HISTORY:
            return <OutputIcon />;
        case ComponentTypes.UNIT_CHART_ENGINE:
            return <OilBarrelIcon />;
        case ComponentTypes.UNIT_CHART_TRANSMISSION:
            return <SensorsIcon />;
        case ComponentTypes.UNIT_CHART_POWER_END:
            return <BoltIcon />;
        case ComponentTypes.UNIT_CHART_LOCKUP:
            return <HttpsIcon />;
        case ComponentTypes.COMPONENT_LIFE:
            return <CategoryIcon />;
        case ComponentTypes.UNIT_FLEET:
            return <LocalShippingIcon />;
        case ComponentTypes.UNIT_ALARM_COUNT:
            return <AccessAlarmIcon />;
        case ComponentTypes.UNIT_ACTIVITY:
            return <TimelineIcon />;
        case ComponentTypes.UNIT_USER_CHART:
            return <StackedLineChartIcon />;
        case ComponentTypes.UNIT_AUDIT_LOG:
            return <ListIcon />;
        case ComponentTypes.DATA_GRID:
            return <GridViewIcon />
        default:
            return <OilBarrelIcon />;
    }
}

const mapStateToProps = (state, props) => {
    const { stateDef } = props;
    let appState = applicationState(state);
    let componentState = treatmentOverviewState(state[stateDef.key]);

    const cardsForDashboard = _.filter(_.map(componentState.cardsList, function (card) {
        return ({
            ...card,
            show: getDisplay(card.type, appState.selectedOwner),
            icon: getIcon(card.type),

        })
    }), ['show', true]);

    return {
        selectedView: componentState.selectedView,
        selectedCard: appState.user.dashboards[ComponentTypes.TREATMENT_OVERVIEW]?.selectedCard,
        dashboards: appState.user.dashboards,
        cardsList: cardsForDashboard,
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onAddCardToDashboard: (view, card) => { dispatch(userConfigActions.addCardToDashboardLayout(ComponentTypes.TREATMENT_OVERVIEW, view, card)); },
        onSelectCard: (card) => { dispatch(userConfigActions.onSelectedCard(ComponentTypes.TREATMENT_OVERVIEW, card)); },
    }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(ConfigPanelTreatmentOverview))