import React, {Component} from "react";
import { compose, withProps } from 'recompose';
import {Box, Button, Grid, IconButton, Paper, Stack, TextField, Tooltip, Icon} from "@mui/material";
import {connect} from "react-redux";
import * as appUserConfigActions from "../../../../state/app/actions/appUserConfigActions";
import {appState as applicationState} from "../../../../state/app/appSelectors";
import _ from "lodash";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ComponentTypes from "../../../componentTypes";
import * as treatmentOverviewActions from "../../../../state/displays/treatmentOverview/treatmentOverviewActions";
import {Container, Draggable} from "@edorivai/react-smooth-dnd";
import MdtIconPicker from "../../../common/iconPicker/mdtIconPicker";
import {FAVORITE_VIEWS_LENGTH} from "../../../common/constants";
import { treatmentOverviewState } from '../../../../state/displays/treatmentOverview/treatmentOverviewSelectors';

const styles = {
    table: {
        width:'477px',
        marginRight: '50px',
        boxShadow: 'none'
    },
    buttonBox: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '65px',
        width: '95%'
    },
    button: {
        margin: '10px'
    },
    discardButton: {
        backgroundColor:'grey.200',
        color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
        '&:hover': {
            backgroundColor:'grey.400',
            color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
        }
    },
    dragIcon: {
        "&:hover": {
            color: 'grey.400',
            transform: 'scale(1.1)'
        }
    }
};

class CustomViewConfigPanel extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const currentDashboard = this.props.dashboards[ComponentTypes.TREATMENT_OVERVIEW];
        const customViews = currentDashboard?.views.views.filter(v => v.type === ComponentTypes.USER_VIEW);
        const favoriteViews = _.filter(customViews, ['isFavorite', true]);
        const shouldShowCancel = currentDashboard.hasChanges !== true;
        const shouldDisableSave = currentDashboard.isValid === false || currentDashboard.hasChanges !== true;

        return (
            <Stack spacing={2} ml={2} mr={2}>
                <Box>
                    <Button onClick={() => {this.props.onAddCustomView();}} color={'inherit'}>
                        <AddIcon/>
                        ADD
                    </Button>
                </Box>
                <Box sx={{ overflowY: "auto", maxHeight: "calc(100vh - 250px)" }}>
                    <Grid container>
                        {
                            !_.isEmpty(customViews) &&(
                                <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={this.props.onReorderCustomViews}>
                                    {customViews.map((customView) => (
                                        <Draggable key={customView.id}>
                                            <Grid container alignItems={'center'}
                                                  sx={{
                                                      minHeight:'60px'}}>
                                                <Grid item sx={{ width: '35px', height: '100%'}}>
                                                    <MdtIconPicker key={customView.id}
                                                                   icon={customView.icon}
                                                                   stateKey={customView.id}
                                                                   searchable={true}
                                                                   onSelectIcon={(event) => {
                                                                       this.props.onSelectIcon(customView.id, event.currentTarget.value);
                                                                   }}
                                                    />
                                                </Grid>
                                                <Grid item sx={{ width: '200px', height: '100%'}}>
                                                    <TextField fullWidth placeholder='Name' size='small' variant='standard'
                                                               inputProps={{maxLength: 40}}
                                                               value={customView.name || ''}
                                                               onChange={(event) =>
                                                                   this.props.onChangeCustomViewName(customView, event.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item sx={{ width: '110px', height: '100%'}}>
                                                  <IconButton
                                                    onClick={() => this.props.onToggleFavoriteView(customView.id)}
                                                    size="small"
                                                    disabled={!customView.isFavorite && favoriteViews.length >= FAVORITE_VIEWS_LENGTH}
                                                  >
                                                    { customView.isFavorite !== true && favoriteViews.length < FAVORITE_VIEWS_LENGTH &&
                                                      <>
                                                      <Tooltip title='Add to Favourite' disableInteractive={true}>
                                                        <BookmarkAddIcon/>
                                                      </Tooltip>
                                                      </>
                                                    }
                                                    { customView.isFavorite !== true && favoriteViews.length >= 3 &&
                                                      <Icon/>
                                                    }
                                                    { customView.isFavorite === true &&
                                                      <>
                                                      <Tooltip title='Remove from Favourite' disableInteractive={true}>
                                                        <BookmarkRemoveIcon/>
                                                      </Tooltip>
                                                      </>
                                                    }
                                                  </IconButton>
                                                  <IconButton
                                                    onClick={() => this.props.onRemoveCustomView(customView.id)}
                                                    size='small'>
                                                    <Tooltip title='Remove Custom View' disableInteractive={true}>
                                                        <DeleteOutlineIcon/>
                                                    </Tooltip>
                                                  </IconButton>
                                                  <IconButton className="drag-handle" sx={styles.dragIcon} disableRipple>
                                                    <Tooltip title='Move view' disableInteractive={true}>
                                                      <DragHandleIcon />
                                                    </Tooltip>
                                                  </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Draggable>
                                    ))}
                                </Container>
                            )
                        }
                    </Grid>
                </Box>
                <Box sx={styles.buttonBox}>
                    <Button sx={{...styles.discardButton, ...styles.button}}
                            variant="contained"
                            onClick={this.props.onDiscardCustomViewConfiguration}>
                        { shouldShowCancel === true ? "Cancel": "Discard" }
                    </Button>
                    {/*when running offline we use the datavan name as the userId*/}
                    <Button sx={styles.button}
                            variant="contained"
                            onClick={() => this.props.onSaveCustomViewConfiguration(this.props.loadedJob.datavan)}
                            disabled={shouldDisableSave}>
                        Save
                    </Button>
                </Box>
            </Stack>);
    }
}

const stateDefinition = (props) => {
  return {
      stateDef: {
          key: _.isNil(props.stateKey) ? ComponentTypes.TREATMENT_OVERVIEW : props.stateKey,
          type: ComponentTypes.TREATMENT_OVERVIEW,
      }
  }
};

const mapStateToProps = (state, props) => {
    const { stateDef } = props;
    let appState = applicationState(state);
    let componentState = treatmentOverviewState(state[stateDef.key]);

    return {
        dashboards: appState.user.dashboards,
        loadedJob: componentState.loadedJob
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onAddCustomView: () => {
            dispatch(appUserConfigActions.onAddCustomView(ComponentTypes.TREATMENT_OVERVIEW));
        },
        onChangeCustomViewName: (view, name) => {
            dispatch(treatmentOverviewActions.onChangeCustomViewName(props.stateDef, ComponentTypes.TREATMENT_OVERVIEW, view, name));
        },
        onToggleFavoriteView: (viewId) => {
            dispatch(appUserConfigActions.onToggleFavoriteView(ComponentTypes.TREATMENT_OVERVIEW, viewId));
        },
        onRemoveCustomView: (viewId) => {
            dispatch(treatmentOverviewActions.onRemoveCustomView(props.stateDef, ComponentTypes.TREATMENT_OVERVIEW, viewId));
        },
        onDiscardCustomViewConfiguration: () => {
            dispatch(treatmentOverviewActions.onDiscardCustomViewConfiguration(props.stateDef));
        },
        onSaveCustomViewConfiguration: (userId) => {
            dispatch(treatmentOverviewActions.saveDashboardToUser(props.stateDef, ComponentTypes.TREATMENT_OVERVIEW, userId));
        },
        onReorderCustomViews: ({ removedIndex, addedIndex }) => {
            dispatch(appUserConfigActions.onReorderCustomViews(ComponentTypes.TREATMENT_OVERVIEW, removedIndex, addedIndex));
        },
        onSelectIcon : (viewId, icon) => {
            dispatch(appUserConfigActions.onSelecteIcon(ComponentTypes.TREATMENT_OVERVIEW, viewId, icon));
        }
    };
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(CustomViewConfigPanel))