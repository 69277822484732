export default {
  TREATMENT_OVERVIEW_QUERY_DATA_STARTING: 'TREATMENT_OVERVIEW_QUERY_DATA_STARTING',
  TREATMENT_OVERVIEW_QUERY_DATA_SUCCESS: 'TREATMENT_OVERVIEW_QUERY_DATA_SUCCESS',
  TREATMENT_OVERVIEW_QUERY_DATA_ERROR: 'TREATMENT_OVERVIEW_QUERY_DATA_ERROR',
  TREATMENT_OVERVIEW_SELECT_CONTEXT: 'TREATMENT_OVERVIEW_SELECT_CONTEXT',
  TREATMENT_OVERVIEW_SELECT_NAVIGATION_CONTEXT: 'TREATMENT_OVERVIEW_SELECT_NAVIGATION_CONTEXT',

  TREATMENT_OVERVIEW_SELECT_VIEW: 'TREATMENT_OVERVIEW_SELECT_VIEW',
  TREATMENT_OVERVIEW_UPDATE_CUSTOM_VIEW_INPUT: 'TREATMENT_OVERVIEW_UPDATE_CUSTOM_VIEW_INPUT',
  TREATMENT_OVERVIEW_SET_TIME_FRAME: 'TREATMENT_OVERVIEW_SET_TIME_FRAME',
  TREATMENT_OVERVIEW_SET_CUSTOM_START_TIME: 'TREATMENT_OVERVIEW_SET_CUSTOM_START_TIME',
  TREATMENT_OVERVIEW_SET_CUSTOM_DURATION: 'TREATMENT_OVERVIEW_SET_CUSTOM_DURATION',

  TREATMENT_OVERVIEW_SELECT_NEXT_CONTEXT_START: 'TREATMENT_OVERVIEW_SELECT_NEXT_CONTEXT_START',
  TREATMENT_OVERVIEW_SELECT_NEXT_CONTEXT_END: 'TREATMENT_OVERVIEW_SELECT_NEXT_CONTEXT_END',
  TREATMENT_OVERVIEW_SELECT_PREV_CONTEXT_START: 'TREATMENT_OVERVIEW_SELECT_PREV_CONTEXT_START',
  TREATMENT_OVERVIEW_SELECT_PREV_CONTEXT_END: 'TREATMENT_OVERVIEW_SELECT_PREV_CONTEXT_END',

  TREATMENT_OVERVIEW_SET_CUSTOM_START_TIME_DISPLAY: 'TREATMENT_OVERVIEW_SET_CUSTOM_START_TIME_DISPLAY',

  TREATMENT_OVERVIEW_SHOW_DIALOG: 'TREATMENT_OVERVIEW_SHOW_DIALOG',

  TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_STARTING: 'TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_STARTING',
  TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_SUCCESS: 'TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_SUCCESS',
  TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_ERROR: 'TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_ERROR',

  TREATMENT_OVERVIEW_LOAD_CARDS_STARTING: 'TREATMENT_OVERVIEW_LOAD_CARDS_STARTING',
  TREATMENT_OVERVIEW_LOAD_CARDS_SUCCESS: 'TREATMENT_OVERVIEW_LOAD_CARDS_SUCCESS',
  TREATMENT_OVERVIEW_LOAD_CARDS_ERROR: 'TREATMENT_OVERVIEW_LOAD_CARDS_ERROR',

  TREATMENT_OVERVIEW_DISCARD_CUSTOM_VIEW_CONFIGURATION: 'TREATMENT_OVERVIEW_DISCARD_CUSTOM_VIEW_CONFIGURATION',
  TREATMENT_OVERVIEW_CARDS_QUERY_RUNNING: 'TREATMENT_OVERVIEW_CARDS_QUERY_RUNNING',
};
