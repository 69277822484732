import _ from "lodash";
import {Box} from '@mui/material';
import ConfigPanelPumpDashboard from '../components/displays/pumpDashboard/configs/configPanelPumpDashboard';
import PumpCustomViewConfigPanel from '../components/displays/pumpDashboard/customView/customViewConfigPanel';
import TreatmentCustomViewConfigPanel from '../components/displays/treatmentOverview/customView/customViewConfigPanel';
import FleetCustomViewConfigPanel from '../components/displays/fleetMap/visuals/customView/fleetCustomViewConfigPanel';
import ConfigPanelFleetDashboard from '../components/displays/fleetMap/configs/configPanelFleetDashboard';
import UnitHoursConfigPanel from "../components/cards/unitHours/unitHoursConfigPanel";
import DataGridConfigPanel from "../components/cards/dataGrid/dataGridConfigPanel"
import ComponentLifeConfigPanel from "../components/cards/componentLife/componentLifeConfigPanel";
import React from "react";
import ComponentTypes from "../components/componentTypes";
import UnitUserChartConfigPanel from '../components/cards/unitUserChart/unitUserChartConfigPanel';
import UnitAlarmCountConfigPanel from "../components/cards/unitAlarmCount/unitAlarmCountConfigPanel";
import FleetPRCHistory from "../components/cards/fleetPRCHistory/fleetPRCHistory";
import FleetClientStatus from "../components/cards/fleetClientStatus/fleetClientStatus";
import FleetComponents from "../components/cards/fleetComponents/fleetComponents";
import FleetOperation from "../components/cards/fleetOperation/fleetOperation";
import FleetVibration from "../components/cards/fleetVibration/fleetVibration";
import FleetActivity from "../components/cards/fleetActivity/fleetActivity";
import FleetAlarmCountConfigPanel from "../components/cards/fleetAlarmCount/fleetAlarmCountConfigPanel";
import UnitAuditLogConfigPanel from "../components/cards/unitAuditLog/unitAuditLogConfigPanel";
import FleetAuditLogConfigPanel from "../components/cards/fleetAuditLog/fleetAuditLogConfigPanel";

import LiveViewConfigPanel from "../components/displays/fleetMap/visuals/liveView/liveViewConfigPanel";
import ConfigPanelTreatmentOverview
  from "../components/displays/treatmentOverview/configs/configPanelTreatmentOverview";

/**
 * Display content for a Component Type in the Context Drawer
 * @param {*} type Component Type to render a context panel for
 * @param {*} data Data related to the Component Type
 * @returns A UI Component to display in the Context Drawer
 */
const renderContextContent = (type, data) => {

    switch (type) {
      // Displays Config Panels
      case ComponentTypes.PUMP_DASHBOARD:
        return <ConfigPanelPumpDashboard/>;
      case ComponentTypes.FLEET_DASHBOARD:
        return <ConfigPanelFleetDashboard/>;
      case ComponentTypes.TREATMENT_OVERVIEW:
        return <ConfigPanelTreatmentOverview/>;

      // Cards Config Panels
      case ComponentTypes.UNIT_HOURS:
        return <UnitHoursConfigPanel card={data} stateKey={data.stateKey} />
      case ComponentTypes.UNIT_USER_CHART:
        return <UnitUserChartConfigPanel card={data} stateKey={data.stateKey}/>
      case ComponentTypes.UNIT_ALARM_COUNT:
        return <UnitAlarmCountConfigPanel card={data} stateKey={data.stateKey}/>
      case ComponentTypes.FLEET_ALARM_COUNT:
        return <FleetAlarmCountConfigPanel card={data} stateKey={data.stateKey}/>
      case ComponentTypes.DATA_GRID:
        return <DataGridConfigPanel card={data} stateKey={data.stateKey} />
      case ComponentTypes.COMPONENT_LIFE:
        return <ComponentLifeConfigPanel card={data} stateKey={data.stateKey} />
      case ComponentTypes.UNIT_AUDIT_LOG:
        return <UnitAuditLogConfigPanel card={data} stateKey={data.stateKey}/>
      case ComponentTypes.FLEET_AUDIT_LOG:
        return <FleetAuditLogConfigPanel card={data} stateKey={data.stateKey}/>
     
      case ComponentTypes.FLEET_PRC_HISTORY:
        return <FleetPRCHistory card={data} stateKey={data.stateKey}/>;
      case ComponentTypes.FLEET_CLIENT_STATUS:
        return <FleetClientStatus card={data} stateKey={data.stateKey}/>
      case ComponentTypes.FLEET_COMPONENTS:
        return <FleetComponents card={data} stateKey={data.stateKey}/>
      case ComponentTypes.FLEET_OPERATION:
        return <FleetOperation card={data} stateKey={data.stateKey}/>
      case ComponentTypes.FLEET_VIBRATION:
        return <FleetVibration card={data} stateKey={data.stateKey}/>
      case ComponentTypes.FLEET_ACTIVITY:
        return <FleetActivity card={data} stateKey={data.stateKey}/>
   
      // Views Config Panels
      case ComponentTypes.CONFIG_PANEL_PUMP_DASHBOARD_CUSTOM_VIEWS:
        return <PumpCustomViewConfigPanel/>;
      case ComponentTypes.CONFIG_PANEL_FLEET_DASHBOARD_CUSTOM_VIEWS:
        return <FleetCustomViewConfigPanel/>;
      case ComponentTypes.CONFIG_PANEL_TREATMENT_OVERVIEW_CUSTOM_VIEWS:
        return <TreatmentCustomViewConfigPanel/>;
      case ComponentTypes.FLEET_LIVE_VIEW:
        return <LiveViewConfigPanel fleetName={data.fleetName} />;
      default:
        return <Box sx={{height: '100%'}}/>;
    }
};

export {
    renderContextContent
};