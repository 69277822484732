import _ from 'lodash';
import defaultActionFactory from '../../common/factories/defaultActionFactory';
import queryActionFactory from "../../common/factories/queryActionFactory";
import errorMessages from '../../common/errorMessages';
import * as navActions from '../../app/actions/appNavActions';
import * as appUserConfigActions from '../../app/actions/appUserConfigActions';
import * as appContextActions from '../../app/actions/appContextActions';
import { handleError } from '../../app/actions/appErrorActions';
import treatmentOverviewViews from './treatmentOverviewViews';

import ComponentTypes from '../../../components/componentTypes';

import { fetchTreatmentOverviewDisplayData, fetchSaveUserDashboard, fetchTreatmentOverviewCards } from "./treatmentOverviewQueries";
import treatmentOverviewActionTypes from './treatmentOverviewActionTypes';

const selectContext = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_CONTEXT, 'stateDef', 'context');
const selectView = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_VIEW, 'stateDef', 'view');
const onUpdateCustomViewInput = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_UPDATE_CUSTOM_VIEW_INPUT, 'stateDef', 'view', 'customViewInput');

const selectNavigationContext = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_NAVIGATION_CONTEXT, 'stateDef', 'context');

const selectNextContextStart = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_NEXT_CONTEXT_START, 'stateDef');
const selectNextContextEnd = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_NEXT_CONTEXT_END, 'stateDef');

const selectPrevContextStart = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_PREV_CONTEXT_START, 'stateDef');
const selectPrevContextEnd = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_PREV_CONTEXT_END, 'stateDef');

const selectNextContext = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(selectNextContextStart(stateDef));

    await dispatch(selectNextContextEnd(stateDef));
  }
}

const selectPrevContext = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(selectPrevContextStart(stateDef));

    await dispatch(selectPrevContextEnd(stateDef));
  }
}

const setTimeFrame = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SET_TIME_FRAME, 'stateDef', 'timeFrame');
const setCustomStartTime = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SET_CUSTOM_START_TIME, 'stateDef', 'startTime');
const setCustomDuration = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SET_CUSTOM_DURATION, 'stateDef', 'duration');

const queryData = queryActionFactory(
  treatmentOverviewActionTypes.TREATMENT_OVERVIEW_QUERY_DATA_STARTING,
  treatmentOverviewActionTypes.TREATMENT_OVERVIEW_QUERY_DATA_SUCCESS,
  treatmentOverviewActionTypes.TREATMENT_OVERVIEW_QUERY_DATA_ERROR,
  errorMessages.ERROR_RETRIEVING_TREATMENT_DATA,
  fetchTreatmentOverviewDisplayData
);

const loadDisplay = (stateDef) => {
  return async (dispatch, getState) => {

    await dispatch(loadCardsList(stateDef));

    // Check if there is a navigation context for this display in the app state
    let navigationContext = getState().app.navigation.context;
    let dashboardContext = navigationContext['/treatment-overview'];
    if (!_.isNil(dashboardContext)) {
      // Clear out the context from the app state now that we have it
      await dispatch(navActions.setNavigationContext('/treatment-overview', null));
    }

    // Execute our display query
    await dispatch(queryData(stateDef, null));

    // Set the selected context
    if (_.isNil(dashboardContext)) {
      await dispatch(selectContext(stateDef, getState()[stateDef.key].selectedContext));
    } else {
      await dispatch(selectNavigationContext(stateDef, dashboardContext));
    }
  }
};

const setCustomStartTimeDisplay = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SET_CUSTOM_START_TIME_DISPLAY, 'stateDef', 'startTime');
const showDialog = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SHOW_DIALOG, 'stateDef', 'show');

const editDashboard = (stateDef, isEdit, dashboard) => {
  return async(dispatch, getState) => {
    await dispatch(appContextActions.setContext(ComponentTypes.TREATMENT_OVERVIEW, {}));
    await dispatch(appContextActions.openContextDrawer(isEdit, getState()[stateDef.key].configPanelWidth));
    await dispatch(appUserConfigActions.onEditDashboard(isEdit, dashboard));
  }
};

const manageCustomViews = (stateDef, isManage, dashboard) => {
  return async(dispatch, getState) => {
    if(isManage === true){
      await dispatch(appContextActions.setContext(ComponentTypes.CONFIG_PANEL_TREATMENT_OVERVIEW_CUSTOM_VIEWS, {dashboard: dashboard}));
    }
    await dispatch(appContextActions.openContextDrawer(isManage, getState()[stateDef.key].customViewsConfigPanelWidth));
    await dispatch(appUserConfigActions.onManageDashboardCustomViews(isManage, dashboard));
  }
}

const undoChangesDialogOK = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(showDialog(stateDef, ""));
    await dispatch(editDashboard(stateDef, false));
    const userId = getState().app.user.userId;
    await dispatch(appUserConfigActions.queryUserConfigurationForDashboardLayout(null, userId));
  }
};

const dialogCancel = (stateDef) => {
  return async(dispatch, getState) => {
    await dispatch(showDialog(stateDef, ""));
  }
};

const saveDashboardToUserStarting = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_STARTING, 'stateDef');
const saveDashboardToUserSuccess = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_SUCCESS, 'stateDef', 'queryResults');
const saveDashboardToUserError = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_ERROR, 'stateDef');

const saveDashboardToUser = (stateDef, name, userId) => {

  return async(dispatch, getState) => {
    let queryResults = null;
    try {

      await dispatch(saveDashboardToUserStarting(stateDef));
      // Setup the input object for the mutation
      let input = 
      {
        userId: userId, // when running offline we use the datavan as the userId
        category: "dashboard",
        definitions: [
          {
            name: name,
            value: JSON.stringify(getState().app.user.dashboards[name].views)
          }
        ]
      }
      queryResults = await fetchSaveUserDashboard(input);

      await dispatch(saveDashboardToUserSuccess(stateDef, queryResults));

      // After saving, reload the user's dashboard config
      await dispatch(appUserConfigActions.queryUserConfigurationForDashboardLayout(null, userId));

      await dispatch(editDashboard(stateDef, false));
      await dispatch(manageCustomViews(stateDef, false, name));

    } catch(e) {
      await dispatch(saveDashboardToUserError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_SAVING_DASHBOARD, e.message));
    }
  }
};

const loadCardsStarting = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_LOAD_CARDS_STARTING, 'stateDef');
const loadCardsSuccess = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_LOAD_CARDS_SUCCESS, 'stateDef', 'cardsList');
const loadCardsError = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_LOAD_CARDS_ERROR, 'stateDef');

const loadCardsList = (stateDef) => {

  return async(dispatch, getState) => {
    let queryResults = null;
    try {
      await dispatch(loadCardsStarting(stateDef));

      queryResults = await fetchTreatmentOverviewCards();

      await dispatch(loadCardsSuccess(stateDef, queryResults));

    } catch(e) {
      await dispatch(loadCardsError(stateDef));
      return dispatch(handleError(errorMessages.ERROR_LOADING_CARDS_LIST, e.message));
    }
  }
};

const onRemoveCustomView = (stateDef, dashboard, viewId) => {
  return async(dispatch, getState) => {
    let selectedView = getState()[dashboard].selectedView;
    if (viewId === selectedView.id) {
      await dispatch(selectView(stateDef,  treatmentOverviewViews.DASHBOARD));
    }
    await dispatch(appUserConfigActions.onRemoveCustomView(dashboard, viewId));
  }
};

const discardCustomViewConfiguration = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_DISCARD_CUSTOM_VIEW_CONFIGURATION, 'stateDef', 'originalViewConfig');

const onDiscardCustomViewConfiguration = (stateDef) => {
  return async(dispatch, getState) => {
    const originalDashboardConfigViews = getState().app.user.dashboards[ComponentTypes.TREATMENT_OVERVIEW].originalConfigViews;
    await dispatch(appUserConfigActions.onDiscardCustomViewConfiguration(ComponentTypes.TREATMENT_OVERVIEW));
    await dispatch(discardCustomViewConfiguration(stateDef, originalDashboardConfigViews));
  }
};

const onChangeCustomViewName = (stateDef, dashboard, view, name) => {
  return async(dispatch, getState) => {
    await dispatch(appUserConfigActions.onChangeCustomViewName(dashboard, view.id, name));
    await dispatch(onUpdateCustomViewInput(stateDef, view, name));
  }
};

const onCardsQueryRunning = defaultActionFactory(treatmentOverviewActionTypes.TREATMENT_OVERVIEW_CARDS_QUERY_RUNNING, 'stateDef', 'queryRunning');

export {
  queryData,
  selectContext,
  selectView,
  onUpdateCustomViewInput,
  selectNavigationContext,
  selectPrevContext,
  selectNextContext,
  loadDisplay,
  setTimeFrame,
  setCustomStartTime,
  setCustomDuration,
  setCustomStartTimeDisplay,
  editDashboard,
  manageCustomViews,
  showDialog,
  undoChangesDialogOK,
  dialogCancel,
  saveDashboardToUser,
  loadCardsList,
  onRemoveCustomView,
  onDiscardCustomViewConfiguration,
  onChangeCustomViewName,
  onCardsQueryRunning
}
