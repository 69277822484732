import _ from 'lodash';

import * as treatmentService from '../services/treatmentService';
import treatmentOverviewActionTypes from '../treatmentOverviewActionTypes';
import { treatmentOverviewState } from '../treatmentOverviewSelectors';
import treatmentOverviewViews from "../treatmentOverviewViews";
import moment from "moment";
import componentTypes from "../../../../components/componentTypes";

const initialState = treatmentOverviewState();

const treatmentReducer = (state = initialState, action) => {

  switch (action.type) {

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_QUERY_DATA_STARTING:
      return { ...state, queryRunning: true };

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_QUERY_DATA_SUCCESS:
      return onQueryDataSuccess(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_QUERY_DATA_ERROR:
      return { ...state, queryRunning: false };

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_CONTEXT:
      return onSelectContext(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_PREV_CONTEXT_START:
    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_NEXT_CONTEXT_START:
      return { ...state, queryRunning: true };

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_NEXT_CONTEXT_END:
      return onSelectNextContext(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_PREV_CONTEXT_END:
      return onSelectPrevContext(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_NAVIGATION_CONTEXT:
      return onSelectNavigationContext(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SELECT_VIEW:
      return onSetSelectedView(state, action);
    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_UPDATE_CUSTOM_VIEW_INPUT:
      return onUpdateCustomViewInput(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SET_TIME_FRAME:
      return onSetTimeFrame(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SET_CUSTOM_START_TIME:
      return onSetStartTime(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SET_CUSTOM_DURATION:
      return onSetDuration(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SET_CUSTOM_START_TIME_DISPLAY:
      return onSetStartTimeDisplay(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SHOW_DIALOG:
      return onShowDialog(state, action);

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_LOAD_CARDS_SUCCESS:
      return onLoadCardsList(state, action);

    // We can reuse similar reducer logic here because the actions all perform the same way
    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_STARTING:
    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_LOAD_CARDS_STARTING:
      return { ...state, queryRunning: true };

    // We can reuse similar reducer logic here because the actions all perform the same way
    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_SUCCESS:
    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_SAVE_DASHBOARD_TO_USER_ERROR:
    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_LOAD_CARDS_ERROR:
      return { ...state, queryRunning: false };

    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_CARDS_QUERY_RUNNING:
      return onCardsQueryRunning(state, action);
    case treatmentOverviewActionTypes.TREATMENT_OVERVIEW_DISCARD_CUSTOM_VIEW_CONFIGURATION:
      return onDiscardCustomViewConfiguration(state, action);

    default: return state;

  }

};

const onQueryDataSuccess = (state, action) => {

  let contextDetails = treatmentService.processJob(action.queryResults.getLoadedJob);

  return {
    ...state,
    queryRunning: false,
    loadedJob: action.queryResults.getLoadedJob,
    contexts: contextDetails.contexts, // side info
    filters: []
  };

};

const onSelectContext = (state, action) => {

  if (!_.isNil(action.context) && _.isEmpty(action.context)) {

    // The AutoComplete control we use will output an empty array when using the keyboard to clear
    // out the selected value. Catch that here and set it to null so the cards all behave properly.

    return { ...state, selectedContext: null };
  }

  let selectedContext = treatmentService.resolveSelectedContext(
    state.contexts,
    _.isNil(action.context) ? action.context : action.context.id
  );

  return { ...state, queryRunning: false, selectedContext: selectedContext };
};

const onSelectNextContext = (state, action) => {
  let contextId = state.selectedContext.id;
  let selectedIndex = state.contexts.findIndex(p => p.id === state.selectedContext.id);

  if (selectedIndex < state.contexts.length - 1) {
    ++selectedIndex;
    contextId = state.contexts[selectedIndex].id
  }

  return onSelectContext(state, { context: { id: contextId } });
};

const onSelectPrevContext = (state, action) => {
  let contextId = state.selectedContext.id;
  let selectedIndex = state.contexts.findIndex(p => p.id === state.selectedContext.id);

  if (selectedIndex > 0) {
    --selectedIndex;
    contextId = state.contexts[selectedIndex].id
  }

  return onSelectContext(state, { context: { id: contextId } });
};

const onSelectNavigationContext = (state, action) => {

  if (_.isNil(action.context) || _.isEmpty(state.contexts)) {

    // If we do not have a context or any contexts just set the selected context to null

    return { ...state, selectedContext: null };
  }

  // Check to see if the context exists in the current contexts

  let selectedContext = _.find(state.contexts, { id: action.context.selectedContextId });

  if (!_.isNil(selectedContext)) {

    // If we have a match then set the selection

    return { ...state, selectedContext: selectedContext };
  }

  if (_.isEmpty(state.appliedFilters)) {

    // If we didn't find a match and there are no configured filters, then set the selection to the first context

    return { ...state, selectedContext: state.contexts[0] };
  }

  // If we get here, then the context is not in the current list of filtered contexts. Process
  // the treatments without any filters and try to resolve the selected context normally.

  let contextDetails = treatmentService.processJob(state.loadedJob);

  selectedContext = treatmentService.resolveSelectedContext(contextDetails.contexts, action.context.selectedContextId);

  return {
    ...state,
    contexts: contextDetails.contexts,
    filters: contextDetails.filters,
    selectedContext: selectedContext,
    appliedFilters: initialState.appliedFilters,
  }
};

const onSetSelectedView = (state, action) => {

  let selectedView = action.view;
  let selectedCustomView = state.selectedCustomView;

  if (!_.isNil(action.view.type) && action.view.type === componentTypes.USER_VIEW) {
    selectedCustomView = action.view;
  }

  // If we are given a view that we don't know about we just set it to the operation view
  selectedView = _.isNil(selectedView) ? treatmentOverviewViews.OPERATION : selectedView;

  return {
    ...state,
    selectedView: selectedView,
    selectedCustomView: selectedCustomView,
    customViewInput: ''
  }
};

const onUpdateCustomViewInput = (state, action) => {
  const newSelectedView = _.cloneDeep(state.selectedView);
  let newCustomViewInput = state.customViewInput;
  if(!_.isNil(newSelectedView?.name)){
    if(newSelectedView.id === action.view?.id) {
      newSelectedView.name = action.customViewInput;
    } else if(_.isNil(action.view)){
      newCustomViewInput = action.customViewInput;
    }
  }
  return {
    ...state,
    selectedView: newSelectedView,
    customViewInput: newCustomViewInput
  };
};

const onSetTimeFrame = (state, action) => {

  let newState = {
    ...state,
    selectedTimeFrame: action.timeFrame,
    selectedCustomStartTime: initialState.selectedCustomStartTime,
    selectedCustomDuration: initialState.selectedCustomDuration,
    selectedCustomStartTimeDisplay: initialState.selectedCustomStartTimeDisplay,
  };

  // If the time frame is set to custom, set the initial start time to the now.

  if (newState.selectedTimeFrame.label === 'Custom') {

    let defaultTime = moment().subtract(initialState.selectedCustomDuration.value, 'minutes').startOf('minute');

    newState = {
      ...newState,
      selectedCustomStartTime: defaultTime,
      selectedCustomStartTimeDisplay: defaultTime,
    }
  }

  return newState;
};

const onSetStartTime = (state, action) => {

  return {
    ...state,
    selectedCustomStartTime: action.startTime,
    selectedCustomStartTimeDisplay: action.startTime
  }
};

const onSetDuration = (state, action) => {

  return {
    ...state,
    selectedCustomDuration: action.duration
  }
};

const onSetStartTimeDisplay = (state, action) => {

  return {
    ...state,
    selectedCustomStartTimeDisplay: action.startTime
  }
};

const onShowDialog = (state, action) => {

  return {
    ...state,
    showDialog: action.show
  }
}

const onLoadCardsList = (state, action) => {
  let formattedList = []
  _.forEach(JSON.parse(action.cardsList), function (card) {
    formattedList.push({
      ...card,
      id: card.type,
      text: {
        // make sure it shows 'Unit Active Alarms' on cards menu.
        // clean solution would be update componentTypes to have UNIT_ALARM_COUNT with value "unitActiveAlarms"
        // but this would mess up existing configuration in Prod, so keep the workaround here to manually rewrite title.
        title: card.type === componentTypes.UNIT_ALARM_COUNT? "Unit Active Alarms": _.startCase(card.type),
        description: card.description ? card.description : null,
      },
    })
  })

  return {
    ...state,
    cardsList: formattedList,
    queryRunning: false,
  }
};

const onCardsQueryRunning = (state, action) => {
  return {
    ...state,
    cardsQueryRunning: action.queryRunning
  }
};

const onDiscardCustomViewConfiguration = (state, action) => {
  const selectedView = action.originalViewConfig?.views.find( view => view.id === state.selectedView?.id);
  return {
    ...state,
    selectedView: selectedView || state.selectedView
  }
};

export default treatmentReducer
