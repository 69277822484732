import _ from 'lodash';
import { filterState } from '../../common/filtering/filterSelectors';
import treatmentOverviewViews from './treatmentOverviewViews';

const treatmentOverviewState = state => {

  let filterDetails = filterState(state);

  return {

    ...filterDetails,

    // The list of sides (the available contexts for the cards)
    contexts: _.isNil(state) ? [] : state.contexts,

    // current job for treatment display
    loadedJob: _.isNil(state) ? null : state.loadedJob,

    // The list of sides for current treatment
    sides: _.isNil(state) ? [] : state.sides,

    // The selected side (the context for the cards)
    selectedContext: _.isNil(state) ? null : state.selectedContext,

    selectedView: _.isNil(state) ? treatmentOverviewViews.DASHBOARD : state.selectedView,

    timeFrames: _.isNil(state) ? [
      { value: 60, label: 'Last hour' },
      { value: 360, label: 'Last 6 hours' },
      { value: 720, label: 'Last 12 hours' },
      { value: 1440, label: 'Last 24 hours' },
      { value: null, label: 'Custom' },
    ] : state.timeFrames,

    customDurations: _.isNil(state) ? [
      { value: 60, label: 'Last hour' },
      { value: 360, label: '6 hours' },
      { value: 720, label: '12 hours' },
      { value: 1440, label: '24 hours' },
    ] : state.customDurations,

    selectedTimeFrame: _.isNil(state) ? { value: 360, label: 'Last 6 hours' } : state.selectedTimeFrame,
    selectedCustomStartTime: _.isNil(state) ? null : state.selectedCustomStartTime,
    selectedCustomDuration: _.isNil(state) ? { value: 360, label: 'Last 6 hours' } : state.selectedCustomDuration,

    selectedCustomStartTimeDisplay: _.isNil(state) ? null : state.selectedCustomStartTimeDisplay,

    queryRunning: _.isNil(state) ? false : state.queryRunning,

    editMode: _.isNil(state) ? false : state.editMode,
    showDialog: _.isNil(state) ? "" : state.showDialog,

    selectedCard: _.isNil(state) ? null : state.selectedCard,

    cardsList: _.isNil(state) ? [] : state.cardsList,

    configPanelWidth: 350,
    customViewsConfigPanelWidth: 400,

    customViewInput: _.isNil(state) ? '' : state.customViewInput,

    cardsQueryRunning: _.isNil(state) ? false : state.cardsQueryRunning,
    
    selectedCustomView: _.isNil(state) ? null : state.selectedCustomView,

  };

};

export {
  treatmentOverviewState
};