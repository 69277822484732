import {performDisplayFetch, performInventoryFetch} from '../common/fetch';
import * as routingService from './services/routingService';
import useSimulationData from '../../helpers/simulationHelper';
import * as appSimulation from './appSimulation';
import ComponentTypes from "../../components/componentTypes";

/**
 * Method to perform the remote query for a list of owners
 */
const fetchOwners = () => {
  return performDisplayFetch({
    'query': '{owners{id ownerName}}'
  })
};

/**
 * Method to perform the query for the list of routes the
 * Portal App should use.
 */
const fetchUserRoutes = (ownerId, showDevelopmentContent, showPreReleaseContent, defaultRoute, pinnedPages, routeGroups) => {
  return routingService.getUserRoutes(ownerId, showDevelopmentContent, showPreReleaseContent, defaultRoute, pinnedPages, routeGroups);
};

/**
 * Method to fetch the local routes for Portal App display without user interaction.
 */
const fetchLocalRoutes = () => {
  return routingService.getLocalRoutes();
}

/**
 * Get a list of Available Displays for the currently selected owner (set via header).
 * @param {*} routes Used for simulation only. This is the user's current list of routes. In simulation mode,
 * the list of available displays is generated using the user's routes.
 * @returns List of available displays in {value label} pairs.
 */
const fetchAvailableDisplays = (routes) => {

  if (useSimulationData('landingPage')) {
    return appSimulation.fetchAvailableDisplays(routes);
  }

  return performDisplayFetch({
    'query': '{ availableDisplays{label value} }'
  });

};

/**
 * Get the user's profile based on (Auth0) user id.
 * @param {*} userId Auth0 specific user id
 * @returns User's profile with some settings
 */
const fetchUserProfileSettings = (userId) => {
  return performDisplayFetch({
    "operationName": "getUserProfileSetting",
    'query': 'query userProfileSetting($userId:String!) { userProfileSetting(userId: $userId) { lastWhatsNewDate landingPage}}',
    'variables': {
      'userId': userId
    }
  });
}

/**
 * Given a setting name and value, save that setting to the user's profile.
 * @param {*} userId Auth0 specific user id
 * @param {*} settingName Name of setting we wish to save
 * @param {*} value Value of that setting
 * @returns Boolean to indicate if the save succeeded or not
 */
const saveUserProfileSetting = (userId, settingName, value) => {

  if (useSimulationData('landingPage')) {
    return appSimulation.fetchSaveLandingPage();
  }

  return performDisplayFetch(
      {
        'query': 'mutation SaveUserProfileSetting($input:UserProfileSettingInput!) { saveUserProfileSetting(input: $input) { success } }',
        'variables': {
          'input': {
            'userId' : userId,
            'name'   : settingName,
            'value'  : value
          }
        }
      });
}

const fetchUserConfigurationForPage = (userId, pageName) => {
  return performDisplayFetch({
    "query": "query userConfigurationForPage($userId:String!, $pageName:String!) { userConfigurationForPage(userId: $userId, pageName: $pageName) { userId name value }}",
    "variables": {
      "userId": userId,
      "pageName": pageName,
    }
  });

};

const fetchUserConfigurationForDashboardLayout = (userId) => {
  if (useSimulationData('userDashboardSetting')) {
    return appSimulation.fetchUserDashboardSettings();
  }
  if (process.env.REACT_APP_OFFLINE_MODE === "true") {
    return performInventoryFetch({
      "query": "query userDashboardConfiguration($userId:String, $dashboardNames:[String!]!) { userDashboardConfiguration(userId: $userId, dashboardNames: $dashboardNames) { name config }}",
      "variables": {
        "userId": userId,
        "dashboardNames": [ComponentTypes.TREATMENT_OVERVIEW],
      }
    });
  }
  return performDisplayFetch({
    "query": "query UserDashboardConfiguration($userId:String!$dashboardNames:[String!]!) { userDashboardConfiguration(userId: $userId, dashboardNames: $dashboardNames) { name config }}",
    "variables": {
      "userId": userId,
      // TODO: as more dashboards load their configs from the cloud, this list will expand
      "dashboardNames": [ComponentTypes.PUMP_DASHBOARD, ComponentTypes.FLEET_DASHBOARD, ComponentTypes.FLEET_LIVE_VIEW],
    }
  });
};

const saveUserPageConfiguration = (userId, pageName, configValue) => {
  return performDisplayFetch(
      {
        "query": "mutation saveUserPageConfiguration($input:PageConfigurationInput!) {  saveUserPageConfiguration(input: $input) { userId name value } }",
        "variables": {
          "input":{
            "userId": userId,
            "pageName": pageName,
            "value": configValue
          }
        }
      }
  );
}

export {
  fetchOwners,
  fetchUserRoutes,
  fetchLocalRoutes,
  fetchAvailableDisplays,
  fetchUserProfileSettings,
  saveUserProfileSetting,
  fetchUserConfigurationForPage,
  saveUserPageConfiguration,
  fetchUserConfigurationForDashboardLayout
}
