import reduceReducers from 'reduce-reducers';

import { treatmentOverviewState } from './treatmentOverviewSelectors';
import treatmentReducer from './reducers/treatmentReducer';
import filterReducer from './reducers/filterReducer';

const initialState = treatmentOverviewState();

const treatmentOverviewReducer = (state=initialState, action) => {

  const reducer = reduceReducers(
    treatmentReducer,
    filterReducer
  );

  return reducer(state, action);

};

export default treatmentOverviewReducer