import '../../../common/styles/shepherd.css'

const steps = [
  {
    id: 'editView',
    attachTo: { element: '.editViewButton', on: 'bottom' },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Exit',
        type: 'cancel'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Next',
        type: 'next'
      }
    ],
    classes: '',
    highlightClass: '',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Edit View',
    text: [
      `
        <p>
        Customize the current view by adding, removing, and configuring cards.
        </p>
        <p>
        The <strong><svg style="height:24px;width:24px;position:relative;top:5px"><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></svg>Dashboard View</strong> 
        is customizable <strong>System</strong> View.
        <br>
        <p>
        All <strong>Custom Views</strong> can be customized.
        </p>
      `
    ],
    when: {
      show: () => {

      },
      hide: () => {

      }
    }
  },
  {
    id: 'customViews',
    attachTo: { element: '.customViewsComboBox', on: 'bottom' },
    buttons: [
      {
        classes: 'shepherd-button-secondary',
        text: 'Back',
        type: 'back'
      },
      {
        classes: 'shepherd-button-primary',
        text: 'Done',
        type: 'cancel'
      }
    ],
    classes: '',
    highlightClass: '',
    scrollTo: false,
    cancelIcon: {
      enabled: true,
    },
    title: 'Custom Views',
    text: [
      `
            <p>
            This is where you select and manage <strong>Custom Views</strong>.
            </p>
            <p>
            <strong>Custom Views</strong> are the views you have created and customized. You can add and remove <strong>Custom Views</strong> at any time. You can also select the icon a <strong>Custom View</strong> has.
            </p>
            <p>
            You can choose up to <strong>3 Custom Views</strong> as <strong>Favourites</strong> and their icons will display here for quick selection.
            </p>
            `
    ],
    when: {
      show: () => {

      },
      hide: () => {

      }
    }
  },
];

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    }
  },
  useModalOverlay: true
};

export {
  steps,
  tourOptions
}