import _ from 'lodash';

const fetchSaveLandingPage = () => {

  return new Promise((resolve) => {

    setTimeout(() => {
      resolve({
        saveUserProfileSetting: {
          success: true
        }
      })
    }, 1000);

  });
};

const fetchAvailableDisplays = (routes) => {

  const simAvailableDisplays = [];

  if (!_.isEmpty(routes)) {

    _.forEach(routes, (route) => {
      simAvailableDisplays.push(
      {
       value: route.value,
       label: route.label, 
      })
    });

  }

  return new Promise((resolve) => {

    setTimeout(() => {
      resolve({
        availableDisplays: simAvailableDisplays
      })
    }, 1000);

  });

}

const fetchUserDashboardSettings = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if(process.env.REACT_APP_OFFLINE_MODE === "true") {
        resolve({
          userDashboardConfiguration: [
            {
              name: "treatmentOverview",
              config: JSON.stringify({
                views: [
                  {
                    id: 'DASHBOARD',
                    name: '',
                    type: 'MDT',
                    allowCustomization: true,
                    cards:
                    // TODO: once we finalize the card types for the treatment overview, we will need to adjust the cards here, e.g. unitUserChart, dataGrid.
                        [
                          {
                            "i": "209ffcbb-cb97-420f-a82a-d7c8bdc65430",
                            "type": "unitUserChart",
                            "x": 0,
                            "y": 0,
                            "w": 6,
                            "h": 3,
                            "minW": 3,
                            "minH": 2,
                            "configuration": {
                              "canFreeSize": true,
                              "sensors": [
                                {
                                  "sensorSetId": 517,
                                  "alias": "Alternator_Voltage",
                                  "uom": "V",
                                  "displayName": "Alternator_Voltage",
                                  "color": "#e57373",
                                  "isVisible": true,
                                  "yAxisId": -2,
                                  "lineStyle": {
                                    "value": [
                                      10,
                                      0
                                    ],
                                    "id": 109
                                  }
                                },
                                {
                                  "sensorSetId": 788,
                                  "alias": "Boost_Press",
                                  "uom": "psi",
                                  "displayName": "Boost_Press",
                                  "color": "#81c784",
                                  "isVisible": true,
                                  "yAxisId": 2,
                                  "lineStyle": {
                                    "value": [
                                      10,
                                      0
                                    ],
                                    "id": 109
                                  }
                                }
                              ],
                              "axes": {
                                "1": {
                                  "min": "",
                                  "max": "",
                                  "errors": {}
                                },
                                "2": {
                                  "min": "",
                                  "max": "",
                                  "errors": {}
                                },
                                "-2": {
                                  "min": "",
                                  "max": "",
                                  "errors": {}
                                },
                                "-1": {
                                  "min": "",
                                  "max": "",
                                  "errors": {}
                                }
                              },
                              "title": "USER CHART"
                            },
                            "isResizable": true,
                            "resizeHandles": [
                              "se"
                            ]
                          },
                          {
                            "i": "3bfaf9b8-df6a-4c18-9ea8-6fd9856e9bf5",
                            "type": "dataGrid",
                            "x": 6,
                            "y": 0,
                            "w": 3,
                            "h": 3,
                            "minW": 1,
                            "minH": 1,
                            "configuration": {
                              "canFreeSize": true,
                              "sensors": [
                                {
                                  "sensorSetId": 517,
                                  "alias": "Alternator_Voltage",
                                  "uom": "V",
                                  "displayName": "Alternator_Voltage",
                                  "isVisible": true,
                                  "conditionalFormatting": {
                                    "applied": false,
                                    "defaultSensorColor": "#e57373",
                                    "rules": []
                                  }
                                },
                                {
                                  "sensorSetId": 1305,
                                  "alias": "Check_Pump",
                                  "uom": "BOOL",
                                  "displayName": "Check_Pump",
                                  "isVisible": true,
                                  "conditionalFormatting": {
                                    "applied": false,
                                    "defaultSensorColor": "#9575cd",
                                    "rules": []
                                  }
                                }
                              ],
                              "dataView": "listView",
                              "title": "DATA GRID"
                            },
                            "isResizable": true,
                            "resizeHandles": [
                              "se"
                            ]
                          },
                          {
                            "i": "5cf469c0-9474-4440-85e0-8b851e9ba810",
                            "type": "dataGrid",
                            "x": 0,
                            "y": 3,
                            "w": 9,
                            "h": 1,
                            "minW": 1,
                            "minH": 1,
                            "configuration": {
                              "canFreeSize": true,
                              "sensors": [
                                {
                                  "sensorSetId": 1318,
                                  "alias": "Actual_Gear",
                                  "uom": "#",
                                  "displayName": "Actual_Gear",
                                  "isVisible": true,
                                  "conditionalFormatting": {
                                    "applied": false,
                                    "defaultSensorColor": "#e57373",
                                    "rules": []
                                  }
                                }
                              ],
                              "dataView": "gridView",
                              "title": "DATA GRID"
                            },
                            "isResizable": true,
                            "resizeHandles": [
                              "se"
                            ]
                          }
                        ]
                  },
                  {
                    id: '8135b5e2-77b7-4c4e-918b-10ee127236fb',
                    name: 'Another Favorite',
                    type: 'USER',
                    isFavorite: true,
                    allowCustomization: true,
                    icon: 'star',
                    cards: [],
                  },
                  {
                    id: '2f36e204-4552-46c0-8f83-8bb53562cdeb',
                    name: 'My Dashboard',
                    type: 'USER',
                    isFavorite: false,
                    allowCustomization: true,
                    icon: 'accessible',
                    cards: [],
                  },
                  {
                    id: 'b3959cc3-7e48-4012-aea6-9e7ba0853cb0',
                    name: 'My Dashboard',
                    type: 'USER',
                    isFavorite: false,
                    allowCustomization: true,
                    icon: 'bookmark',
                    cards: [],
                  },
                ]
              },)
            }
          ]
        })
      }
      else {
        resolve({
          userDashboardConfiguration: [
            {
              name: "pumpDashboard",
              config: JSON.stringify({
                views: [
                  {
                    id: 'DASHBOARD',
                    name: '',
                    type: 'MDT',
                    allowCustomization: true,
                    cards:
                        [
                          {i: 'unitHours', type: 'unitHours', x: 6, y: 0, w: 3, h: 2 },
                          {i: 'unitHistory', type: 'unitChartPressureHistory', x: 0, y: 0, w: 4, h: 2, configuration: { showRate: true } },
                          {i: 'unitActivity', type:'unitActivity', x: 4, y: 1, w: 2, h: 1 },
                          {i: 'unitFleet', type: 'unitFleet', x: 4, y: 0, w: 2, h: 1 },
                          {i: 'componentLife', type: 'componentLife', x: 4, y: 2, w: 3, h: 2  },
                          {i: 'vibrationHistory', type: 'unitChartVibrationHistory', x: 0, y: 2, w: 4, h: 2 },
                          {i: 'pumpAlarm', type: 'unitAlarmCount', x:7, y:2, w:2, h:1},
                        ]
                  },
                  {
                    id: 'OPERATION',
                    name: '',
                    type: 'MDT',
                    allowCustomization: true,
                    cards:
                        [
                          {i: 'unitChartEngine', type: 'unitChartEngine',  x: 0, y: 0, w: 4, h: 2 },
                          {i: 'unitChartTransmission', type: 'unitChartTransmission',  x: 4, y: 0, w: 4, h: 2 },
                          {i: 'unitChartPowerEnd', type: 'unitChartPowerEnd',  x: 0, y: 2, w: 4, h: 2 },
                          {i: 'unitChartLockup', type: 'unitChartLockup',  x: 4, y: 2, w: 4, h: 2 },
                        ]
                  },
                  {
                    id: 'DATA_EXPLORATION',
                    name: '',
                    type: 'MDT',
                    allowCustomization: false
                  },
                  {
                    id: '80343ead-39c8-449a-909c-a688635bce60',
                    name: 'My Favorite',
                    type: 'USER',
                    isFavorite: true,
                    allowCustomization: true,
                    icon: 'favorite',
                    cards: [],
                  },
                  {
                    id: '8135b5e2-77b7-4c4e-918b-10ee127236fb',
                    name: 'Another Favorite',
                    type: 'USER',
                    isFavorite: true,
                    allowCustomization: true,
                    icon: 'star',
                    cards: [],
                  },
                  {
                    id: '2f36e204-4552-46c0-8f83-8bb53562cdeb',
                    name: 'My Dashboard',
                    type: 'USER',
                    isFavorite: false,
                    allowCustomization: true,
                    icon: 'accessible',
                    cards: [],
                  },
                  {
                    id: 'b3959cc3-7e48-4012-aea6-9e7ba0853cb0',
                    name: 'My Dashboard',
                    type: 'USER',
                    isFavorite: false,
                    allowCustomization: true,
                    icon: 'bookmark',
                    cards: [],
                  },
                ]
              },)
            }
          ]
        })
      }
    }, 1000);
  });
};

export { fetchSaveLandingPage, fetchAvailableDisplays, fetchUserDashboardSettings }