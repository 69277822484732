import React, { Component, Fragment, useContext } from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { IconButton, Typography, Tooltip, Box, Chip, Button, Divider, Icon, Grid } from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import FilterDialog from '../../common/filtering/filterDialog';
import EditIcon from '@mui/icons-material/Edit';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';

import Progress from "../../controls/progress";
import DashboardView from './views/dashboard';
import ComponentTypes from '../../componentTypes';
import * as treatmentOverviewActions from '../../../state/displays/treatmentOverview/treatmentOverviewActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import { treatmentOverviewState } from '../../../state/displays/treatmentOverview/treatmentOverviewSelectors';
import {appState as applicationState } from '../../../state/app/appSelectors';
import treatmentOverviewViews from '../../../state/displays/treatmentOverview/treatmentOverviewViews';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import getDisplayStyles from '../../common/styles/displayStyles';
import { AutoCompleteMDT, DateTimePickerMDT } from '../../controls/mdtMuiControls';
import ConfirmationDialog from '../../controls/dashboards/confirmationDialog';
import * as contextActions from '../../../state/app/actions/appContextActions';
import * as appUserConfigActions from "../../../state/app/actions/appUserConfigActions";
import * as appUserActions from '../../../state/app/actions/appUserActions';
import CustomView from "./views/customView";

import { ShepherdTour, ShepherdTourContext } from 'react-shepherd'
import { steps, tourOptions } from './shepherd/shepherdOptions';

const displayStyles = getDisplayStyles();
const styles = {
  ...displayStyles,
  contextSelection: {
    width: '150px',
    marginLeft: 1,
    paddingRight: 1,
  },
  dateTimeControl: {
    // NOTE: The padding below is a bit of a hack to
    // get the 3rd Party datetime picker to align
    // correctly with the other controls.
    paddingTop: 2,
  },
  treatmentNextPrevActions: {
    marginRight: 6
  },
  pageHeaderActions: {
    ...displayStyles.pageHeaderActions,
    alignItems: 'center',
  },
  editModePageHeaderActions: {
    ...displayStyles.pageHeaderActions,
    alignItems: 'center',
    flexGrow: 1
  },
  editModeActions: {
    ...displayStyles.pageHeaderActions,
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center'
  },
  actionButton: {
    backgroundColor: 'grey.200',
    color: (theme) => theme.palette.getContrastText(theme.palette.grey[200]),
    '&:hover': {
      backgroundColor: 'grey.400',
      color: (theme) => theme.palette.getContrastText(theme.palette.grey[400]),
    }
  },
  actionsDivider: {
    height: '36px',
    marginLeft: '12px',
    marginRight: '12px'
  },
  filterContainer: {
    ...displayStyles.filterContainer,
    paddingLeft: '0px',
  },
  pageHeaderButton: {
    ...displayStyles.pageHeaderButton,
    paddingLeft: '6px',
  }
};

const ShepherdButton = (props) => {
  let tour = useContext(ShepherdTourContext);

  return (
      <Box sx={{ marginLeft: '12px' }}>
        <Tooltip title="Assistance">
          <Box>
            <IconButton
                onClick={() => { tour.start(); }}
                size="large"
                disabled={props.isDisabled}
            >
              <LiveHelpIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
  )
}

class TreatmentOverview extends Component {

  constructor(props) {
    super(props);
    this.manualRefreshTime = null;
  }

  componentDidMount() {
    // Load the overview data. NOTE: Loading the display data implicitly
    // handles the setting of the selected context which will trigger the
    // loading of the data age if necessary (see componentDidUpdate)
    this.props.loadDisplay();
    // Start the timer for refreshing the display.
    this.periodicDisplayRefreshId = this.periodicRefreshDisplay();

    // Show the Context Drawer if we left Treatment Overview in Edit Mode, went somewhere else, and are coming back
    if (this.props.editMode === true) {
      this.props.onEditDashboard(true);
    }

    // Show the custom view drawer if we left Treatment Overview and are coming back
    if (this.props.isManageCustomViews === true) {
      this.props.onManageCustomViews(true);
    }
  }

  componentWillUnmount() {
    // Stop the timer for refreshing the display.
    if (!_.isNil(this.periodicDisplayRefreshId)) {
      clearInterval(this.periodicDisplayRefreshId);
    }
    // Stop the timer for refreshing the relative time label
    if (!_.isNil(this.periodicRelativeTimeRefreshId)) {
      clearInterval(this.periodicRelativeTimeRefreshId);
    }
    // Hide the Context Drawer if it's opened and we are going away from the Treatment Overview
    if ((this.props.editMode === true || this.props.isManageCustomViews) &&
        (this.props.contextDrawerOpen || this.props.contextDrawerMinimized)) {
      this.props.onOpenContextDrawer(false, this.props.configPanelWidth);
      this.props.onSelectCard(null);
    }
  }

  componentDidUpdate(prevProps) {
    //Check if selectedContext has been reset or not, it doesn't matter if it's been set to the exact same context(refreshing) or not.
  }

  periodicRefreshDisplay() {
    // Periodically refresh the display. NOTE: Loading the display data
    // implicitly handles the setting of the selected context which will
    // trigger the loading of the data age if necessary (see componentDidUpdate)
    return setInterval(() => {
      // If we are in Edit mode, do not perform automatic refreshes
      if (this.props.editMode !== true && this.props.isManageCustomViews !== true) {
        this.props.loadDisplay();
        this.props.onRefreshTimestamp(moment().unix());
      }
    }, 180000); // 3 minutes
  }

  manualRefreshDisplay() {
    // Refresh the display data when a user manual triggers a refresh.
    // NOTE: Loading the display data implicitly handles the setting of
    // the selected context which will trigger the loading of the data
    // age if necessary (see componentDidUpdate)
    this.props.loadDisplay();
    this.manualRefreshTime = moment().unix();
    this.props.onRefreshManualTimestamp(moment().unix());
  }

  render() {

    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));
    let showTimeFilters = this.props.selectedView.type === ComponentTypes.USER_VIEW;
    let showAdditionalTimeFilters = showTimeFilters === true && !_.isNil(this.props.selectedTimeFrame) && this.props.selectedTimeFrame.label === 'Custom';

    let foundView = _.isEmpty(this.props.user.dashboards) ? null : _.find(this.props.user.dashboards[ComponentTypes.TREATMENT_OVERVIEW].views.views, ['id', this.props.selectedView?.id ?? this.props.selectedView]);
    let allowCustomization = (_.isNil(foundView) ? false : foundView.allowCustomization);
    const manageViewOption = { id: 'MANAGE_VIEWS', name: 'Manage Views', type: ComponentTypes.USER_VIEW, isFavorite: false, icon: 'dashboard_customize' };
    const customViews = [
      ..._.filter(this.props.dashboardViews, ['type', ComponentTypes.USER_VIEW]),
      manageViewOption
    ];
    const favoriteViews = _.filter(this.props.dashboardViews, ['isFavorite', true]);
    const filterOptions = createFilterOptions({
      stringify: (option) => option.name,
    });
    let isInvalidSelectedCustomView = _.isNil(this.props?.selectedCustomView?.name) || !_.includes(customViews.map(x => x.id), this.props?.selectedCustomView?.id);

    return (
        <Box sx={styles.page}>
          <Box sx={{...styles.pageContent, width: '100%', flexFlow: 'row nowrap', justifyContent: 'flex-start'}}>
            <Box sx={{...styles.pageHeader, flexGrow: 1 }}>
              <Box sx={{ ...styles.pageHeaderTitle, width: '280px' }}>
                <Typography variant={"h6"}>Treatment Overview</Typography>
                {
                  <ShepherdTour steps={steps} tourOptions={tourOptions}>
                    <ShepherdButton isDisabled={this.props.isManageCustomViews || this.props.editMode === true} />
                  </ShepherdTour>
                }
              </Box>
              {
                  this.props.editMode === true &&
                  <Box sx={styles.editModePageHeaderActions}>
                    <Divider sx={styles.actionsDivider} orientation='vertical' />
                    <Box sx={{ ...styles.editModeActions, visibility: (!_.isNil(this.props.contextData.card) ? 'hidden' : 'visibile') }}>
                      <Box sx={{ display: 'flex', flexFlow: 'row-reverse nowrap', marginRight: 2, flexGrow: 0.5 }}>
                        <Typography variant='caption'>Add, Remove, Configure, and Position Cards</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexFlow: 'row nowrap', marginLeft: 2, flexGrow: 0.5 }}>
                        <Button sx={{ ...styles.actionButton, marginRight: 2 }} variant='contained'
                          onClick={() => { this.props.user.dashboards[ComponentTypes.TREATMENT_OVERVIEW].hasChanges ? this.props.onShowDialog("undoChanges") : this.props.onUndoChangesDialogOK(); }}>
                          {
                            this.props.user.dashboards[ComponentTypes.TREATMENT_OVERVIEW].hasChanges ? 'Discard Changes' : 'Cancel'
                          }
                        </Button>
                        {/*when running offline we use the datavan name as the userId*/}
                        <Button variant='contained' color='primary' onClick={() => { this.props.onSaveDashboardToUser(ComponentTypes.TREATMENT_OVERVIEW, this.props.loadedJob?.datavan); }}
                                disabled={!this.props.user.dashboards[ComponentTypes.TREATMENT_OVERVIEW].hasChanges}>Save</Button>

                      </Box>
                    </Box>
                  </Box>
              }
              {
                  this.props.editMode !== true &&
                  <>
                    <Box sx={styles.pageHeaderActions}>
                      <Divider sx={styles.actionsDivider} orientation='vertical' />
                      {
                          allowCustomization &&
                          <>
                            <Box sx={{ ...styles.pageHeaderButton, paddingLeft: 0 }}>
                              <Tooltip title="Edit View" className='editViewButton'>
                                <Box>
                                  <IconButton
                                      onClick={() => { this.props.onEditDashboard(true); }}
                                      size="large"
                                      disabled={this.props.isManageCustomViews === true}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                              </Tooltip>
                            </Box>
                          </>
                      }
                      <Box sx={styles.pageHeaderButton}>
                        <Tooltip title="Refresh">
                          <IconButton
                              onClick={() => this.manualRefreshDisplay()}
                              size="large"
                          >
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Divider sx={styles.actionsDivider} orientation='vertical' />
                      <Box sx={{ ...styles.pageHeaderButton, paddingLeft: 0 }}>
                        <Tooltip title="Dashboard View">
                          <IconButton
                              onClick={() =>
                                  this.props.selectView(treatmentOverviewViews.DASHBOARD)
                              }
                              color={
                                this.props.selectedView === treatmentOverviewViews.DASHBOARD
                                    ? 'primary'
                                    : 'inherit'
                              }
                              size="large"
                          >
                            <DashboardIcon />
                          </IconButton>
                        </Tooltip>
                        <svg height="3" width="51">
                          <line
                              x1="0"
                              y1="0"
                              x2="51"
                              y2="0"
                              stroke="#CE4300"
                              strokeWidth="3"
                              visibility={
                                this.props.selectedView === treatmentOverviewViews.DASHBOARD
                                    ? "visible"
                                    : "hidden"
                              }
                          />
                        </svg>
                      </Box>
                    </Box>
                    <Divider sx={styles.actionsDivider} orientation='vertical' />
                    <Box sx={styles.filterContainer} className="customViewsComboBox">
                      <AutoCompleteMDT
                          sx={{ ...styles.dashboardSelection, width: '200px', paddingLeft: '0px' }}
                          fullWidth forcePopupIcon
                          options={customViews}
                          filterOptions={filterOptions}
                          getOptionLabel={(view) => view.name ?? ''}
                          renderOption={(props, option, state) => {
                            return (
                                <Fragment key={option.id}>
                                  {option.id === manageViewOption.id && <hr />}
                                  <li {...props}>
                                    <Grid container spacing={1}>
                                      <Grid item sx={{ width: '30px' }}>
                                        <Icon font='small'>{option.icon}</Icon>
                                      </Grid>
                                      <Grid item sx={{
                                        width: '125px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
                                      }}>{option.name}</Grid>
                                    </Grid>
                                  </li>
                                </Fragment>
                            )
                          }}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          value={this.props.selectedCustomView}
                          inputValue={this.props.customViewInput}  //set input value to keep selected option as is when click on manage view option
                          getOptionDisabled={option => option.id === manageViewOption.id && this.props.isManageCustomViews}
                          renderInput={(params) => {
                            const inputProps = { ...params.InputProps, style: { fontSize: "1.15rem" }, disableUnderline: true };
                            return <>
                              <TextField {...params}
                                         InputProps={inputProps}
                                         autoFocus variant="standard"
                                         placeholder={isInvalidSelectedCustomView ? "Custom Views" : this.props.selectedCustomView.name}
                              />
                            </>;
                          }}
                          onInputChange={(event, newInputValue) => {
                            if (!_.isNil(event) && event && event.type !== 'blur' && newInputValue !== manageViewOption.name) {
                              this.props.onUpdateCustomViewInput(newInputValue);
                            }
                          }}
                          onChange={(event, value, reason) => {
                            if (value.id === manageViewOption.id) {
                              this.props.onManageCustomViews(true);
                            } else {
                              this.props.selectView(value);
                            }
                          }}
                      />
                    </Box>
                    {
                        !_.isEmpty(favoriteViews) && favoriteViews.slice(0, 3).map(favoriteView => {
                          return (
                              <Fragment key={favoriteView.id}>
                                <Box sx={styles.pageHeaderButton}>
                                  <Tooltip title={favoriteView.name}>
                                    <IconButton
                                        onClick={() =>
                                            this.props.selectView(favoriteView)
                                        }
                                        color={
                                          this.props.selectedView?.id === favoriteView.id
                                              ? 'primary'
                                              : 'inherit'
                                        }
                                        size="large"
                                    >
                                      <Icon>{favoriteView.icon}</Icon>
                                    </IconButton>
                                  </Tooltip>
                                  <svg height="3" width="51">
                                    <line
                                        x1="0"
                                        y1="0"
                                        x2="51"
                                        y2="0"
                                        stroke="#CE4300"
                                        strokeWidth="3"
                                        visibility={
                                          this.props.selectedView?.id === favoriteView.id
                                              ? "visible"
                                              : "hidden"
                                        }
                                    />
                                  </svg>
                                </Box>
                              </Fragment>
                          );
                        })
                    }

                    {
                        // only show filters when not in offline mode, feature will be implemented when cloud based treatment overview is required
                        process.env.REACT_APP_OFFLINE_MODE !== "true" &&
                        <>
                        <Divider sx={styles.actionsDivider} orientation='vertical' />
                        <Box sx={styles.filterContainer}>
                          <Typography variant={"subtitle1"}>FILTER:</Typography>
                          {this.props.appliedFilters.map((filterContext, index) => {
                            return (
                                <Chip sx={styles.childComponent}
                                      key={index}
                                      label={filterContext.chipLabel}
                                      onDelete={() => this.props.deleteFilterContext(index)}
                                />
                            );
                          })}
                          <IconButton sx={styles.childComponent}
                                      onClick={() => this.props.openFilterDialog()}
                                      disabled={emptyFilters}
                                      size="large"
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </Box>
                        </>
                  }
                  </>
              }
            </Box>
            <Box id="screenshot-area" sx={{ height: "max-content", width: "fit-content", backgroundColor: "grey.900" }}>
              {
                  this.props.editMode !== true &&
                  <Box sx={styles.selectionContainer}>
                    <Typography variant={"subtitle1"}>SIDE:</Typography>
                    <AutoCompleteMDT
                        sx={styles.contextSelection}
                        options={this.props.contexts}
                        value={this.props.selectedContext}
                        onChange={(event, value, reason) => {
                          this.props.selectContext(value);
                        }}
                        noOptionsText={"No sides found..."}
                    />
                    <Box sx={styles.treatmentNextPrevActions}>
                      <Tooltip title=
                                   {
                                     (!_.isEmpty(this.props.contexts) &&
                                         _.first(this.props.contexts) === this.props.selectedContext) ?
                                         "No Prev Treatment" :
                                         "Prev Treatment"
                                   }>
                    <span>
                      <IconButton
                          disabled={
                              (!_.isEmpty(this.props.contexts) &&
                                  _.first(this.props.contexts) === this.props.selectedContext) ||
                              (this.props.cardsQueryRunning)
                          }
                          onClick={() => this.props.selectPrevContext()}
                          size="large"
                      >
                        <NavigateBeforeIcon />
                      </IconButton>
                    </span>
                      </Tooltip>

                      <Tooltip title={
                        (!_.isEmpty(this.props.contexts) &&
                            _.last(this.props.contexts) === this.props.selectedContext) ?
                            "No Next Treatment" :
                            "Next Treatment"
                      }>
                    <span>
                      <IconButton
                          disabled={
                              (!_.isEmpty(this.props.contexts) &&
                                  _.last(this.props.contexts) === this.props.selectedContext) ||
                              (this.props.cardsQueryRunning)
                          }
                          onClick={() => this.props.selectNextContext()}
                          size="large"
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </span>
                      </Tooltip>
                    </Box>
                    {showTimeFilters === true && (
                        <Box sx={styles.timeFilterContainer}>
                          <Typography variant={"subtitle1"}>TIME FRAME:</Typography>
                          <Box sx={styles.timeFilterItem}>
                            <AutoCompleteMDT
                                sx={styles.timeFrameSelection}
                                getOptionLabel={(timeFrame) => timeFrame.label}
                                options={this.props.timeFrames}
                                value={this.props.selectedTimeFrame}
                                onChange={(event, value, reason) => {
                                  this.props.setTimeFrame(value);
                                }}
                                noOptionsText={"No data found..."}
                            />
                          </Box>
                        </Box>
                    )}
                    {showAdditionalTimeFilters === true && (
                        <Box sx={styles.timeFilterContainer}>
                          <Typography variant={"subtitle1"}>START:</Typography>
                          <Box sx={styles.timeFilterItem}>
                            <div>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePickerMDT
                                    value={this.props.selectedCustomStartTimeDisplay}
                                    onAccept={(value) => {
                                      if (!_.isNil(value)) {
                                        this.props.setCustomStartTime(value);
                                      }
                                    }}
                                    onChange={(value) => {
                                      if (!_.isNil(value)) {
                                        this.props.setCustomStartTimeDisplay(value);
                                      }
                                    }}
                                />
                              </LocalizationProvider>
                            </div>
                          </Box>
                          <Typography variant={"subtitle1"}>DURATION:</Typography>
                          <Box sx={styles.timeFilterItem}>
                            <AutoCompleteMDT
                                sx={styles.timeFrameSelection}
                                options={this.props.customDurations}
                                value={this.props.selectedCustomDuration}
                                onChange={(event, value, reason) => {
                                  this.props.setCustomDuration(value);
                                }}
                                noOptionsText={"No durations found..."}
                            />
                          </Box>
                        </Box>
                    )}
                  </Box>
              }
              {
                  this.props.editMode === true &&
                  <Box sx={styles.selectionContainer} />
              }

              <Box sx={styles.viewContainer}>
                {this.props.selectedView === treatmentOverviewViews.DASHBOARD && (
                    <DashboardView
                        treatment={this.props.selectedContext}
                        user={this.props.user}
                        editMode={this.props.editMode === true}
                        cardsMetaconfig={this.props.cardsList}
                    />
                )}
                {this.props.selectedView?.type === ComponentTypes.USER_VIEW && (
                  <CustomView
                    treatment={this.props.selectedContext}
                    selectedTimeFrame={this.props.selectedTimeFrame}
                    selectedCustomStartTime={this.props.selectedCustomStartTime}
                    selectedCustomDuration={this.props.selectedCustomDuration}
                    manualRefreshTime={this.manualRefreshTime}
                    owner={ComponentTypes.TREATMENT_OVERVIEW}
                    user={this.props.user}
                    editMode={this.props.editMode === true}
                    viewConfig={this.props.dashboardViews.find(view => view.id === this.props.selectedView?.id)}
                    cardsMetaconfig={this.props.cardsList}
                  />
                )}
              </Box>
            </Box>
            <FilterDialog stateDef={this.props.stateDef} />
            <Progress open={this.props.queryRunning} />
            <ConfirmationDialog showDialog={this.props.showDialog}
                                okCallback={() => this.props.onUndoChangesDialogOK()}
                                cancelCallback={() => { this.props.onDialogCancel(); }} />
          </Box>
        </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.TREATMENT_OVERVIEW : props.stateKey,
      type: ComponentTypes.TREATMENT_OVERVIEW,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let appState = applicationState(state);
  let componentState = treatmentOverviewState(state[stateDef.key]);
  const dashboard = appState.user.dashboards[ComponentTypes.TREATMENT_OVERVIEW];

  return {
    contexts: componentState.contexts,
    selectedContext: componentState.selectedContext,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    selectedView: componentState.selectedView,
    timeFrames: componentState.timeFrames,
    customDurations: componentState.customDurations,
    selectedTimeFrame: componentState.selectedTimeFrame,
    selectedCustomStartTime: componentState.selectedCustomStartTime,
    selectedCustomDuration: componentState.selectedCustomDuration,
    user: appState.user,
    selectedCustomStartTimeDisplay: componentState.selectedCustomStartTimeDisplay,
    queryRunning: componentState.queryRunning,
    showDialog: componentState.showDialog, // should be a string: "undoChanges"
    editMode: dashboard?.editMode,
    isManageCustomViews: dashboard?.isManageCustomViews,
    contextDrawerOpen: appState.context.contextDrawerOpen,
    contextDrawerMinimized: appState.context.contextDrawerMinimized,
    configPanelWidth: componentState.configPanelWidth,
    contextData: appState.context.contextData,
    dashboardViews: dashboard?.views?.views ?? [],
    customViewInput: componentState.customViewInput,
    cardsQueryRunning: componentState.cardsQueryRunning,
    selectedCustomView: componentState.selectedCustomView,
    cardsList: componentState.cardsList,
    loadedJob: componentState.loadedJob,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadDisplay: () => { dispatch(treatmentOverviewActions.loadDisplay(props.stateDef)) },
    selectContext: (context) => { dispatch(treatmentOverviewActions.selectContext(props.stateDef, context)) },
    selectNextContext: () => { dispatch(treatmentOverviewActions.selectNextContext(props.stateDef)) },
    selectPrevContext: () => { dispatch(treatmentOverviewActions.selectPrevContext(props.stateDef)) },
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef)) },
    deleteFilterContext: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index)) },
    selectView: (view) => { dispatch(treatmentOverviewActions.selectView(props.stateDef, view)) },
    onUpdateCustomViewInput: (customViewInput) => { dispatch(treatmentOverviewActions.onUpdateCustomViewInput(props.stateDef, null, customViewInput)) },
    onDiscardCustomViewConfiguration: () => { dispatch(appUserConfigActions.onDiscardCustomViewConfiguration(ComponentTypes.TREATMENT_OVERVIEW)) },
    setTimeFrame: (timeFrame) => { dispatch(treatmentOverviewActions.setTimeFrame(props.stateDef, timeFrame)) },
    setCustomStartTime: (startTime) => { dispatch(treatmentOverviewActions.setCustomStartTime(props.stateDef, startTime)) },
    setCustomDuration: (duration) => { dispatch(treatmentOverviewActions.setCustomDuration(props.stateDef, duration)) },
    setCustomStartTimeDisplay: (startTime) => { dispatch(treatmentOverviewActions.setCustomStartTimeDisplay(props.stateDef, startTime)) },

    onEditDashboard: (isEdit) => { dispatch(treatmentOverviewActions.editDashboard(props.stateDef, isEdit, ComponentTypes.TREATMENT_OVERVIEW)); },
    onManageCustomViews: (isManage) => { dispatch(treatmentOverviewActions.manageCustomViews(props.stateDef, isManage, ComponentTypes.TREATMENT_OVERVIEW)); },
    onShowDialog: (show) => { dispatch(treatmentOverviewActions.showDialog(props.stateDef, show)); },
    onUndoChangesDialogOK: () => { dispatch(treatmentOverviewActions.undoChangesDialogOK(props.stateDef)); },
    onDialogCancel: () => { dispatch(treatmentOverviewActions.dialogCancel(props.stateDef)); },
    onSaveDashboardToUser: (name, userId) => { dispatch(treatmentOverviewActions.saveDashboardToUser(props.stateDef, name, userId)); },
    onSelectCard: (card) => { dispatch(appUserConfigActions.onSelectedCard(props.stateDef, card)); },
    onRefreshTimestamp: (timestamp) => { dispatch(appUserActions.onRefreshTimestamp(timestamp)); },
    onRefreshManualTimestamp: (timestamp) => { dispatch(appUserActions.onRefreshManualTimestamp(timestamp)); },

    onOpenContextDrawer: (open, width) => { dispatch(contextActions.openContextDrawer(open, width)); },
  }
};

export default compose(
    withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(TreatmentOverview))
