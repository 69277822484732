let config = {
  displayServiceHost: '',
  displayServiceRoute: 'displayapi',
  cardServiceHost: '',
  cardServiceRoute: 'cardapi',
  inventoryServiceHost: '',
  inventoryServiceRoute: 'inventoryapi',
  protocol: ''
};

const loadConfig = async () => {
  if (process.env.NODE_ENV === 'production') {
    try {
      let url = '{baseRoute}/config'.replace('{baseRoute}', process.env.REACT_APP_PROD_BASE_ROUTE);
      let response = await fetch(url);
      config = await response.json();
      // In production we need to use the protocol that the browser is using (so we can support both http and https)
      config.protocol = window.location.protocol + '//';
    } catch(error) {
      console.error('Error: Failed to load application configuration. ' + error);
    }
  }
};

const getInventoryApiUrl = () => {
  // We are using a proxy module in development mode (setupProxy.js) to map our requests and it expects the
  // url to simply be a relative path for which it tries to match. So the config needs to be setup up with
  // appropriate defaults (no protocol or host). In production mode these other details will be populated
  // (by env variables and browser values) to form a fully qualified path.
  return '{protocol}{inventoryServiceHost}/{inventoryServiceRoute}/graphql'
      .replace('{protocol}', config.protocol)
      .replace('{inventoryServiceHost}', config.inventoryServiceHost)
      .replace('{inventoryServiceRoute}', config.inventoryServiceRoute);
}

const getCardApiUrl = () => {
  // We are using a proxy module in development mode (setupProxy.js) to map our requests and it expects the
  // url to simply be a relative path for which it tries to match. So the config needs to be setup up with
  // appropriate defaults (no protocol or host). In production mode these other details will be populated
  // (by env variables and browser values) to form a fully qualified path.
  return '{protocol}{cardServiceHost}/{cardServiceRoute}/graphql'
    .replace('{protocol}', config.protocol)
    .replace('{cardServiceHost}', config.cardServiceHost)
    .replace('{cardServiceRoute}', config.cardServiceRoute);
};

const getDisplayApiUrl = () => {
  // We are using a proxy module in development mode (setupProxy.js) to map our requests and it expects the
  // url to simply be a relative path for which it tries to match. So the config needs to be setup up with
  // appropriate defaults (no protocol or host). In production mode these other details will be populated
  // (by env variables and browser values) to form a fully qualified path.
  return '{protocol}{displayServiceHost}/{displayServiceRoute}/graphql'
    .replace('{protocol}', config.protocol)
    .replace('{displayServiceHost}', config.displayServiceHost)
    .replace('{displayServiceRoute}', config.displayServiceRoute);
};

const getGoogleAnalyticsId = () => {
  return (process.env.NODE_ENV === 'production') ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID : process.env.REACT_APP_GOOGLE_ANALYTICS_ID_DEV;
}

export {
  loadConfig,
  getCardApiUrl,
  getDisplayApiUrl,
  getInventoryApiUrl,
  getGoogleAnalyticsId,
};